import React, { useEffect, useState } from "react";
import Container from "../../atoms/Container";
import PrimaryButton from "../../atoms/Buttons/ButtonWithArrow";
import Navigation from "../../atoms/Navigation";
import { Link } from "gatsby";
import { useHeaderSettings } from "../../../hooks/useHeaderSettings";
import menu from "../../../images/menu.svg"
import menuClose from "../../../images/menuclose.svg"
import supportIcon from "../../../images/support.svg"
import callIcon from "../../../images/call.svg"
import supportHoverIcon from "../../../images/supportHover.svg"
import callHoverIcon from "../../../images/callHover.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import './header.css'

export default function MegaHeader({scrolled, activeSub, menuState, subHandler, mobileClickHandler, mobileCloseHandler}) {

    const [width, setWidth] = useState()

    const headerSettings = useHeaderSettings()

    const logo = headerSettings.wp.siteLogo
    const header = headerSettings.wp.acfOptionsHeader.header
    
    const teamlidAfbeelding = getImage(header.supportCta.medewerker.teamlid.teamlid.afbeelding.localFile.childImageSharp.gatsbyImageData)

      useEffect(()=>{
        setWidth(window.innerWidth)
      },[])
    
    return (
        <div className={`hq-header__mega ${scrolled ? 'hq-menu-scrolled' : ''}`}>
            <Container styles={'flex justify-between'}>
                <div className="flex items-center">
                    <Link to="/"><img className="hq-header__logo" width={`${logo.width}px`} height={`${logo.height}px`} src={logo.localFile.publicURL} onMouseEnter={() => { subHandler(undefined) }} onClick={()=>{mobileCloseHandler()}} /></Link>
                    <Navigation width={width} state={menuState} activeSub={activeSub} subHandler={subHandler} closeMobile={mobileCloseHandler} />
                </div>
                <div className="hq-header__buttons">
                    {
                        header.supportCta.link ?
                            <a href={header.supportCta.link} target="_blank">
                                <div className="hq-header__buttons--support">
                                    {width > 787 ? <div><GatsbyImage image={teamlidAfbeelding} alt="" className="hq-header__buttons--afbeelding pulse-light"></GatsbyImage></div> : null}
                                    <PrimaryButton label={width > 787 ? header.supportCta.label : null} target={header.supportCta.link} color={'light'} styles={'border border-lightGray'} icon={callIcon} iconHover={callHoverIcon} iconPosition={'before'} />
                                </div>
                            </a>
                            : null
                    }
                    {
                        header.cta.link && width > 787 ?
                            <PrimaryButton label={header.cta.label} target={header.cta.link} color={'dark'} />
                            : null
                    }
                    {width <= 787 ?
                        <PrimaryButton label={menuState ? 'Sluit' : 'Menu'} color={'dark'} icon={menuState ? menuClose : menu} iconPosition={'before'} styles={'z-[100]'} width={width} clickHandler={mobileClickHandler} />
                        : null}
                </div>
            </Container>
        </div>
    )
}